.cookieNotice {
  position: fixed;
  z-index: 10;
  bottom: 20px;
  right: 20px;
  display: none;
  flex-flow: row nowrap;
  align-items: center;
  padding: 20px 25px;
  border-radius: 100px;
  background: map-get($colors, gray25);
  box-shadow: 0 0 10px rgba(map-get($colors, black), 0.15);
  font-size: 0;
  color: map-get($colors, main);

  &.-visible {
    display: flex;
  }

  @include breakpoint(mobile) {
    right: 10px;
    bottom: 10px;
    left: 10px;
    align-items: stretch;
  }

  & .icon {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    flex-shrink: 0;
  }

  & .paragraph {
    margin-bottom: 0;
    margin-right: 20px;
    flex-shrink: 1;
  }

  & .button {
    flex-shrink: 0;
  }
}
