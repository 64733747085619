.tag {
  display: inline-block;
  vertical-align: top;
  font-family: $font-family;
  color: map-get($colors, gray600);
  margin-bottom: 5px;
  margin-right: 12px;
  white-space: nowrap;
  font-size: 0;

  &:last-child {
    margin-right: 0;
  }

  &__icon {
    display: inline-block;
    vertical-align: middle;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 4px;

    @include breakpoint(mobile) {
      width: 14px;
      height: 14px;
    }
  }

  &__text {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    font-size: 11px;
    line-height: 1.1em;

    @include breakpoint(mobile) {
      font-size: 9px;
    }
  }

  &.-large {
    & .tag__text {
      font-size: 16px;

      @include breakpoint(mobile) {
        font-size: 12px;
      }
    }
  }

  &.-topAlignIcon {
    & .tag__text {
      vertical-align: top;
    }
  }

  &.-small {
    color: map-get($colors, gray500);
  }
}
