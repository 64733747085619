.package {
  display: inline-block;
  vertical-align: top;
  width: calc(33.333% - 10px);
  margin-bottom: 30px;
  margin-left: 10px;
  background-color: map-get($colors, white);
  box-shadow: 0 2px 15px rgba(map-get($colors, black), 0.15);

  @include breakpoint(tablet) {
    @include grid-column(12);
    max-width: 500px;
    margin-left: 0;
  }

  &__header {
    padding: 23px;
    text-align: center;
    background-color: rgba(map-get($colors, main), 0.6);

    & .title.-h3 {
      margin-bottom: 0;
      padding-bottom: 0;
      color: map-get($colors, white);
    }
  }

  &__body {
    min-height: 300px;
    padding: 20px 12px;

    & .title.-h2 {
      font-size: 32px;
      text-align: center;
      letter-spacing: 0;

      & small {
        display: block;
        font-family: $font-family;
        font-weight: 400;
        text-transform: none;
        font-size: 13px;
        line-height: 1.4em;
      }
    }
  }

  &__row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 20px;
    border-bottom: solid 1px map-get($colors, gray200);
    font-size: 13px;

    &:last-child {
      border-bottom: none;
    }

    & :nth-child(1) {
      text-align: left;
    }

    & :nth-child(2) {
      text-align: right;
    }

    & b {
      margin-right: 10px;
    }
  }

  &__footer {
    padding: 0 20px 40px;
    text-align: center;
  }

  // Featured package
  &.-featured {
    position: relative;
    z-index: 3;

    & .package__header {
      padding-top: 38px;
      padding-bottom: 38px;
      background-color: map-get($colors, main);
    }

    & .package__footer {
      padding-top: 20px;
      padding-bottom: 50px;
    }
  }
}
