// Config
@import 'config';

// Set default colors
$colors: map-merge(
  $colors,
  (
    main: map-get($colors, green),
    complementary: map-get($colors, blue),
    headerBackground: map-get($colors, white),
    headerBorder: map-get($colors, gray100),
    buttonText: map-get($colors, gray600)
  )
);

$logo-nav-height: 44px;
$logo-main-height: 32px;

// Bootstrap
@import 'bootstrap';
