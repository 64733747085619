.ad {
  position: relative;
  width: 100%;
  margin: 3rem 0;
  border: solid 1px map-get($colors, gray200);
  color: map-get($colors, gray400);
  text-transform: uppercase;
  text-align: center;
  background-color: map-get($colors, gray50);

  .hiddenAds & {
    display: none !important;

    & + .separator {
      display: none;
    }
  }

  @include breakpoint(mobile) {
    margin: 2rem 0;
  }

  &__info {
    position: absolute;
    top: -16px;
    right: 0;
    font-size: 10px;
    text-transform: uppercase;
    color: map-get($colors, gray400);
  }

  & a {
    display: block;

    &:focus {
      outline: 1px dotted map-get($colors, gray400);
    }
  }

  & img {
    display: block;
    width: 100%;
  }

  &.-top {
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;

    @include breakpoint(mobile) {
      margin-top: 15px;
      margin-bottom: 20px;
    }
  }

  &.-size728x90 {
    max-height: 132px;
    max-width: 730px;

    @include breakpoint(mobile) {
      display: none;
    }
  }

  &.-size300x100 {
    display: none;
    max-height: 102px;
    max-width: 302px;

    @include breakpoint(mobile) {
      display: block;
    }
  }

  &.-size250x250 {
    display: inline-block;
    // max-height: 252px;
    max-width: 252px;
    margin: 0;

    & + .ad {
      margin-top: 10px;
    }

    @include breakpoint(tablet) {
      display: block;
      margin-right: auto;
      margin-left: auto;
    }

    @include breakpoint(mobile) {
      margin-top: 10px;
    }
  }

  &.-size470x120 {
    display: block;
    max-width: 472px;
    max-height: 122px;
    margin: 3rem auto 2rem;
  }

  &.-hasText {
    margin-top: 16px;
  }
}
