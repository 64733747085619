// WP form
.wpcf7 {
  margin-top: 26px;

  & .screen-reader-response {
    display: none;
  }

  & p {
    line-height: 1em;
  }

  & label {
    font-size: 16px;
    font-weight: 300;
    color: map-get($colors, gray600);
  }

  & .wpcf7-text,
  & .wpcf7-textarea {
    font-family: $font-family;
    margin-top: 8px;
    padding: 8px 10px;
    font-size: 16px;
    line-height: 1em;
    border-radius: 0;
    border: solid 1px map-get($colors, gray200);

    &.wpcf7-not-valid {
      border-color: map-get($colors, red);
    }
  }

  & .wpcf7-not-valid-tip {
    display: block;
    font-size: 12px;
    margin-top: 5px;
    color: map-get($colors, red);
  }

  & .wpcf7-submit {
    @extend .button;
  }

  & .wpcf7-response-output {
    color: map-get($colors, green);
    line-height: 1.4em;
  }

  & .wpcf7-validation-errors,
  & .wpcf7-mail-sent-ng {
    color: map-get($colors, red);
  }
}

