.button {
  @include reset-input(button);
  padding: 14px 30px;
  border: solid 2px map-get($colors, main);
  border-radius: 50px;
  font-family: $font-family-alt;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  color: map-get($colors, buttonText);
  transition: color 0.2s map-get($easings, swiftOut), background-color 0.2s map-get($easings, swiftOut);
  user-select: none;

  &:hover,
  &:focus {
    color: map-get($colors, white);
    background-color: map-get($colors, main);
  }

  &:active {
    border-color: darken(map-get($colors, main), 3%);
    color: map-get($colors, white);
    background-color: darken(map-get($colors, main), 3%);
    transition: all 0.1s map-get($easings, swiftOut);
  }

  & + .button {
    margin-left: 15px;
  }

  &__icon {
    margin-right: 4px;
  }

  & .icon {
    width: 18px;
    height: 18px;
    margin-top: -4px;
    margin-bottom: -4px;
  }

  &.-white {
    border-color: map-get($colors, white);
    color: map-get($colors, main);
    background-color: map-get($colors, white);

    &:hover,
    &:focus {
      color: lighten(map-get($colors, main), 15%);
    }

    &:active {
      color: map-get($colors, main);
    }
  }

  &.-gray {
    border-color: map-get($colors, gray600);
    color: map-get($colors, gray600);

    &:hover,
    &:focus {
      color: map-get($colors, white);
      background-color: map-get($colors, gray600);
    }

    &:active {
      border-color: darken(map-get($colors, gray600), 3%);
      background-color: darken(map-get($colors, gray600), 3%);
    }
  }

  &.-loading {
    flex-grow: 0;
    width: 44px;
    height: 44px;
    padding: 0;
    border-left-color: rgba(map-get($colors, main), 0.3);
    font-size: 0;
    background-color: map-get($colors, white);
    pointer-events: none;
    animation: spinner 1s linear infinite;

    & span,
    & svg {
      transform: scale(0);
    }
  }

  &.-textNeutral {
    color: map-get($colors, white);
  }

  &.-textDark {
    color: map-get($colors, gray600);

    &:hover,
    &:focus {
      color: map-get($colors, white);
    }
  }

  &.-square {
    padding-right: 21px;
    padding-left: 21px;
  }

  &.-scrollTop {
    position: fixed;
    z-index: 8;
    right: 40px;
    bottom: 40px;
    display: none;
    padding: 14px;
    backface-visibility: hidden;
    border-color: map-get($colors, gray300);
    border-radius: 2px;
    background-color: map-get($colors, white);

    &:hover,
    &:focus {
      color: map-get($colors, gray800);
    }
  }

  &.-loadMore {
    margin-top: 30px;
    margin-bottom: 30px;
    visibility: hidden;
    opacity: 0;

    @include breakpoint(tablet) {
      margin-top: 20px;
      margin-bottom: 60px;
    }

    &.-visible {
      visibility: visible;
      opacity: 1;
    }
  }

  &.-tiny {
    padding: 12px 25px;
    font-size: 11px;
  }

  &.-small {
    padding: 12px 25px;
  }
}
