.input {
  &.-text {
    @include reset-input('text');
    display: inline-block;
    vertical-align: top;
    width: 100%;
    max-width: 100%;
    padding: 7px 0;
    border-bottom: solid 1px map-get($colors, main);
    font-family: $font-family;
    font-size: 18px;
    line-height: 1em;
    color: map-get($colors, main);
    transition: all 200ms map-get($easings, appleEase);

    &:focus {
      border-color: map-get($colors, main);
    }

    &::placeholder {
      color: rgba(map-get($colors, main), 0.6);
    }

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px map-get($colors, white) inset;
    }
  }

  &.-gray {
    border-bottom-color: map-get($colors, gray500);
    color: map-get($colors, gray800);

    &:focus {
      border-color: map-get($colors, gray800);
    }

    &::placeholder {
      color: map-get($colors, gray600);
    }
  }

  &.-large {
    font-size: 26px;

    @include breakpoint(mobile) {
      font-size: 18px;
    }
  }

  &.-horizontal {
    width: auto;
    vertical-align: baseline;
  }

  &.-small {
    font-size: 16px;
  }

  &.-date {
    background-image: url('../images/angle-down.svg');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 16px 16px;
  }
}
