.card {
  position: relative;
  z-index: 3;
  display: block;
  flex-grow: 1;
  padding: 10px;
  margin: 0 -10px 30px;
  text-decoration: none;
  text-align: left;
  background-color: map-get($colors, white);

  // Hover effect if card is a link
  @at-root a#{&} {
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: 0 3px 13px rgba(map-get($colors, gray800), 0.2);
      opacity: 0;
      transition: opacity 0.2s map-get($easings, appleEaseAlt);
    }

    &:hover,
    &:focus {
      outline: none;

      &::before {
        opacity: 1;
      }
    }
  }

  .-loading & {
    opacity: 0.4;
    pointer-events: none;
  }

  &__figure {
    position: relative;
    height: 0;
    width: 100%;
    padding-top: 56.25%;
    text-align: center;
    background-size: cover;
    background-position: center center;
    background-color: map-get($colors, gray400);

    & .card__info {
      transform: translateY(-50%);
    }
  }

  &__info {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: top;
    padding: 9px 10px;
    color: map-get($colors, white);
    font-size: 12px;
    font-weight: 400;
    background-color: map-get($colors, main);

    & i {
      display: inline-block;
      vertical-align: middle;
      height: 3px;
      width: 3px;
      border-radius: 50%;
      margin: 0 4px;
      background-color: map-get($colors, white);
    }
  }

  &__content {
    padding: 25px 5px;

    & :last-child {
      margin-bottom: 0;
    }
  }

  &__source {
    font-size: 11px;
    color: map-get($colors, gray800);
    font-weight: 300;

    & .icon {
      height: 1.2em;
      width: 1.2em;
      margin-right: 2px;
    }

    & .icon,
    & span {
      vertical-align: middle;
    }
  }

  &__breadcrumbs {
    font-size: 14px;
    color: map-get($colors, gray600);

    & li {
      display: inline-block;
      vertical-align: baseline;
      margin-right: 2px;
      margin-bottom: 4px;

      & + li {
        margin-left: 2px;
      }
    }
  }

  &__tags {
    margin-bottom: 6px;
  }

  & .title.-h2 {
    margin-bottom: 0.2em;
  }

  & .title.-line {
    margin-top: 0.6em;
  }

  & .paragraph {
    color: map-get($colors, gray600);

    &.-small {
      line-height: 1.5em;
    }
  }

  // Large modifier
  &.-large {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    flex-grow: 1;
    margin-bottom: 10px;

    @include breakpoint(mobile) {
      flex-flow: row wrap;
      margin-right: 0;
      margin-left: 0;
    }

    & .card__content {
      flex-basis: calc(100% - 295px);
      flex-grow: 1;
      padding-top: 0;
      padding-bottom: 10px;

      @include breakpoint(tablet) {
        flex-basis: calc(100% - 225px);
      }

      @include breakpoint(mobile) {
        display: flex;
        flex-flow: column wrap;
        flex-basis: 100%;
        padding-bottom: 0;
      }
    }

    & .card__tags {
      margin-top: 5px;

      @include breakpoint(mobile) {
        order: 2;
        margin-top: 8px;
        margin-bottom: 0;

        & .tag.-author {
          display: none;
        }
      }
    }

    & .card__figure {
      padding-top: 0;
      height: 220px;
      width: 270px;
      margin-right: 25px;
      flex-basis: 270px;

      @include breakpoint(tablet) {
        flex-basis: 200px;
        height: 180px;
      }

      @include breakpoint(mobile) {
        height: 0;
        padding-top: 56.25%;
        width: 100%;
        flex-basis: 100%;
        margin-bottom: 10px;
        margin-right: 0;
      }
    }

    & .title.-line {
      & ~ .card__figure,
      & ~ .card__content {
        margin-top: 12px;
      }
    }
  }

  // Medium
  &.-medium {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    flex-grow: 1;
    margin: 0 0 10px;

    @include breakpoint(mobile) {
      flex-flow: row wrap;
      margin-right: 0;
      margin-left: 0;

      & .paragraph {
        display: none;
      }
    }

    & .card__tags {
      flex-basis: 100%;

      @include breakpoint(mobile) {
        order: 2;
        margin-bottom: 0;

        & .tag.-author {
          display: none;
        }
      }
    }

    & .card__figure {
      padding-top: 0;
      margin-right: 20px;
      margin-bottom: 10px;
      height: 120px;
      width: 120px;
      flex-basis: 120px;
      margin-top: 5px;

      @include breakpoint(mobile) {
        height: 80px;
        width: 80px;
        flex-basis: 80px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }

    & .card__content {
      flex-grow: 1;
      padding-top: 0;
      padding-bottom: 10px;
      width: calc(100% - 150px);
      flex-basis: calc(100% - 150px);

      @include breakpoint(tablet) {
        padding-bottom: 0;
      }
    }
  }

  // Tiny modifier
  &.-tiny {
    display: flex;
    flex-flow: row no-wrap;
    border-bottom: solid 1px map-get($colors, gray300);
    margin: 0;
    padding: 10px 0 15px;

    &:last-child {
      border-bottom: 0;
    }

    &::before {
      display: none;
    }

    &:hover,
    &:focus {
      & .title {
        color: map-get($colors, main);
      }
    }

    & .card__tags {
      margin-bottom: 0;
    }

    & .card__content {
      flex-grow: 1;
      padding: 0;
    }

    & .card__figure {
      width: 55px;
      height: 55px;
      flex-shrink: 0;
      padding-top: 0;
      margin-right: 10px;
    }

    & .title {
      line-height: 1.2em;
      transition: color 200ms map-get($easings, appleEase);
    }

    & .tag {
      vertical-align: top;
    }
  }

  // Related modifier
  &.-related {
    display: flex;
    flex-flow: row no-wrap;
    align-items: flex-start;
    flex-grow: 1;
    margin-right: 0;
    margin-left: 0;
    padding-bottom: 20px;
    border-bottom: solid 1px map-get($colors, gray300);
    transition: border 200ms map-get($easings, appleEase);

    @include breakpoint(tablet) {
      margin-bottom: 30px;
    }

    &:hover {
      border-color: map-get($colors, gray100);
    }

    & .card__content {
      flex-grow: 1;
      padding-top: 0;
      padding-bottom: 0;
    }

    & .card__figure {
      padding-top: 0;
      width: 110px;
      height: 110px;
      flex-shrink: 0;
      margin-right: 15px;

      @include breakpoint(mobile) {
        display: none;
      }
    }
  }

  // Event modifier
  &.-event {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 0;
    margin-left: 0;
    flex-grow: 1;
    padding: 0;
    border: solid 1px map-get($colors, gray100);

    @include breakpoint(mobile) {
      margin-bottom: 10px;
    }

    & .card__tags {
      margin-top: 0;
      margin-bottom: 10px;
    }

    & .tag {
      color: map-get($colors, main);
    }

    & .card__content {
      flex-grow: 1;
      padding: 0 25px;
      margin: 20px 0;
      border-left: solid 1px map-get($colors, gray100);

      @include breakpoint(tablet) {
        padding: 0 20px;
      }
    }

    & .card__figure {
      position: static;
      flex-shrink: 0;
      width: 150px;
      min-height: 100px;
      height: auto;
      padding: 0;
      margin: 20px 25px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: transparent;

      @include breakpoint(tablet) {
        margin: 20px 15px;
        width: 80px;
        min-height: 70px;
      }
    }

    & .title.-h4 {
      line-height: 1.2em;
    }

    // Event large
    &.-large {
      flex-flow: row nowrap;
      margin-bottom: 30px;

      @include breakpoint(700px) {
        flex-flow: column wrap;
      }

      & .card__content {
        border-left: none;
        padding-right: 40px;

        @include breakpoint(700px) {
          display: block;

          & .card__text {
            position: relative;
            max-height: 4.8em;
            overflow: hidden;

            &::after {
              content: '';
              position: absolute;
              top: 0.8em;
              right: 0;
              left: 0;
              height: 4em;
              background: linear-gradient(rgba(255, 255, 255, 0.01), map-get($colors, white));
            }
          }
        }
      }

      & .card__text {
        margin-bottom: 1em;
      }

      & .card__figure {
        position: relative;
        flex-basis: 200px;
        min-height: 140px;
        margin-left: 40px;

        @include breakpoint(700px) {
          order: -1;
          margin: 10px auto 0;
          width: 120px;
          min-height: 120px;
          max-width: 100%;
          flex-basis: auto;

          &::before {
            display: none;
          }
        }

        &::before {
          content: '';
          position: absolute;
          left: -40px;
          top: 10px;
          bottom: 10px;
          width: 1px;
          background-color: map-get($colors, gray100);
        }
      }

      & .card__footer {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-start;

        @include breakpoint(tablet) {
          flex-flow: column wrap;
        }
      }

      & .card__tags {
        @include breakpoint(tablet) {
          margin-bottom: 20px;
        }

        @include breakpoint(mobile) {
          order: 0;
        }

        & .tag {
          margin-right: 30px;
          max-width: 320px;
        }
      }
    }
  }

  &.-listItem {
    border-bottom: solid 1px map-get($colors, gray100);
  }

  // Page
  &.-page {
    border: solid 1px map-get($colors, gray100);
    padding: 20px;
    margin-right: 0;
    margin-left: 0;

    & .card__content {
      padding: 0;
    }
  }

  // Contact
  &.-contact {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 0;
    margin-right: 0;
    padding: 20px 20px 25px;
    box-shadow: 0 2px 8px rgba(map-get($colors, black), 0.12);

    & .card__content {
      padding: 0 20px 0 5px;
      flex-grow: 1;
      flex-basis: calc(100% - 130px);

      & .title.-h4 {
        margin-bottom: 0;
      }

      & > .paragraph {
        margin-bottom: 0.8em;
        line-height: 1.2em;
      }

      & li {
        display: flex;
        flex-flow: row nowrap;
        align-items: baseline;
        margin-bottom: 0;
        line-height: 1.4em;

        & .icon {
          flex-shrink: 0;
          margin-right: 6px;
          color: map-get($colors, gray700);
        }

        & a {
          word-break: break-word;
        }

        & + li {
          margin-top: 10px;
        }
      }
    }

    & .card__figure {
      padding-top: 0;
      height: 150px;
      flex-basis: 115px;
      flex-shrink: 0;

      @include breakpoint(tablet) {
        flex-basis: 80px;
        height: 100px;
      }
    }
  }

  // Member
  &.-member {
    margin-left: 0;
    margin-right: 0;
    padding: 20px;
    min-height: 215px;
    box-shadow: 0 2px 8px rgba(map-get($colors, black), 0.12);

    & .card__content {
      padding: 0 20px 0 5px;
    }

    & .paragraph.-small {
      font-size: 13px;
    }

    & .card__expandable {
      position: relative;
      max-height: 75px;
      overflow: hidden;
      cursor: pointer;
      transition: height 200ms map-get($easings, appleEase);

      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 75%;
        background: linear-gradient(rgba(255, 255, 255, 0.01), map-get($colors, white) 75%);
      }

      & .icon {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        color: map-get($colors, gray500);
      }

      &.-open {
        cursor: default;
        max-height: none;

        &::after {
          display: none;
        }

        & .icon {
          display: none;
        }
      }
    }
  }

  // File
  &.-file {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 10px;
    border-bottom: solid 1px map-get($colors, gray300);

    & .card__content {
      padding-top: 10px;
    }
  }

  // External
  &.-external {
    & .card__content {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    & .card__info {
      margin-bottom: 15px;
    }
  }

  // Sponsored
  &.-sponsored {
    flex-wrap: wrap;
    background-color: rgba(map-get($colors, main), 0.05);

    & .title.-line span {
      background-color: rgba(map-get($colors, main), 0.05);
    }
  }
}
