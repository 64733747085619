.flatpickr-day {
  line-height: 36px;

  &.today {
    border-color: map-get($colors, main);

    &:hover,
    &:focus {
      border-color: map-get($colors, main);
      background: map-get($colors, main);
    }
  }

  &.selected,
  &.selected:focus,
  &.selected:hover {
    border-color: map-get($colors, main);
    background: map-get($colors, main);
  }
}

.flatpickr-next-month svg:hover,
.flatpickr-prev-month svg:hover {
  fill: map-get($colors, main);
}

.flatpickr-calendar.open {
  box-shadow: 0 2px 10px rgba(map-get($colors, gray900), 0.2);
}

.flatpickr-mobile {
  @extend .input;
  @extend .-text;
  @extend .-gray;
  @extend .-small;
  @extend .-horizontal;
  @extend .-date;
  min-width: 180px;
}
