// Fade in
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// Spinner
@keyframes spinner {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

// Slide in down
@keyframes slideInDown {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

// Shake
@keyframes shake {
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

// Hamburger
@keyframes hamburgerLineTopIn {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(6px);
  }

  100% {
    transform: translateY(6px) rotate(45deg);
  }
}

@keyframes hamburgerLineTopOut {
  0% {
    transform: translateY(6px) rotate(45deg);
  }

  50% {
    transform: translateY(6px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes hamburgerLineBottomIn {
  0% {
    transform: translateY(12px);
  }

  50% {
    transform: translateY(6px);
  }

  100% {
    transform: translateY(6px) rotate(-45deg);
  }
}

@keyframes hamburgerLineBottomOut {
  0% {
    transform: translateY(6px) rotate(-45deg);
  }

  50% {
    transform: translateY(6px);
  }

  100% {
    transform: translateY(12px);
  }
}
