.modal {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 8px 0;
  height: 100vh;
  width: 100%;
  visibility: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &.-show {
    visibility: visible;
  }

  &__container {
    margin: auto 10px;
    visibility: hidden;
    transform: scale(0.9);
    max-width: 430px;
    border: solid 1px map-get($colors, gray200);
    opacity: 0;
    background-color: map-get($colors, white);
    transition: all 200ms map-get($easings, appleEase);
    will-change: opacity, transform;

    @include breakpoint(mobile) {
      margin: 8px;
    }

    .-show & {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }

    .-shake & {
      animation: shake 500ms ease both;
    }
  }

  &__header {
    position: relative;
    border-bottom: solid 1px map-get($colors, gray200);
    padding: 22px 70px;
    text-align: center;
    background-color: map-get($colors, gray50);

    @include breakpoint(mobile) {
      padding: 16px 24px;
    }

    & .title.-alt {
      margin-bottom: 0;
    }
  }

  &__body {
    padding: 30px 70px;
    text-align: center;

    @include breakpoint(mobile) {
      padding: 24px;
    }
  }

  &__footer {
    padding: 30px 70px;
    border-top: solid 1px map-get($colors, gray200);
    text-align: center;

    @include breakpoint(mobile) {
      padding: 24px;
    }

    & :last-child {
      margin-bottom: 0;
    }
  }

  &__close {
    @include reset-input('button');
    position: absolute;
    top: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
    color: map-get($colors, main);
    transition: color 200ms map-get($easings, swiftOut);

    &:hover,
    &:focus {
      color: darken(map-get($colors, main), 10%);
    }

    &:active {
      color: map-get($colors, main);
    }

    & .icon {
      height: 18px;
      width: 18px;
    }
  }
}
