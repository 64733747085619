.icon {
  display: inline-block;
  vertical-align: baseline;
  width: 1em;
  height: 1em;
  fill: currentColor;
  overflow: hidden;
  pointer-events: none;

  &.-w20 {
    width: 20px;
    height: 20px;
  }

  &.-large {
    width: 2em;
    height: 2em;
  }
}
