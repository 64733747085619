.section {
  padding-top: 40px;
  padding-bottom: 40px;

  @include breakpoint(mobile) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  & + .section {
    border-top: solid 1px map-get($colors, gray200);
  }

  &__contactImage {
    height: 115px;
    width: 115px;
    border-radius: 50%;
    margin: 0 auto 20px;
    overflow: hidden;

    & img {
      width: 100%;
    }
  }

  &__link {
    margin-top: 10px;
    text-align: center;

    @include breakpoint(tablet) {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  &__title,
  &__title.title,
  &__title.title.-alt {
    margin-bottom: 30px;
  }

  &__title.title.-h4 {
    margin-bottom: 20px;
  }

  &.-large {
    padding-top: 60px;
    padding-bottom: 60px;

    @include breakpoint(mobile) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &.-gray {
    background-color: map-get($colors, gray50);
  }

  &.-lightGray {
    background-color: map-get($colors, gray25);
  }

  &.-small {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &.-centerText {
    text-align: center;
  }

  &.-noBorder {
    border: none;
  }

  &.-borderTop {
    border-top: solid 1px map-get($colors, gray200);
  }

  &.-noPaddingTop {
    padding-top: 0;
  }

  &.-overlap {
    position: relative;
    z-index: 2;
    margin-top: -50px;
    border-radius: 2px;
    text-align: center;
    background-color: map-get($colors, white);

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      display: block;
      border-radius: 2px;
    }

    &::before {
      z-index: -2;
      height: 100px;
      box-shadow: 0 0 10px rgba(map-get($colors, gray800), 0.2);
    }

    &::after {
      z-index: -1;
      height: 110px;
      background-color: map-get($colors, white);
    }

    & .grid__column {
      text-align: left;
    }
  }
}
