.featureItem {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;

  & .grid__column {
    @include breakpoint(tablet) {
      text-align: center !important;
    }
  }

  &:first-child {
    margin-top: 30px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(even) {
    & .grid__column.-desktop-2 {
      order: -1;
    }
  }

  & .grid__column.-desktop-2 {
    @include breakpoint(tablet) {
      order: -1;
      margin-bottom: 20px;
    }
  }
}
