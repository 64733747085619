.label {
  line-height: 1em;
  color: map-get($colors, gray800);

  &.-large {
    font-size: 26px;

    @include breakpoint(mobile) {
      font-size: 18px;
    }
  }

  &.-small {
    font-size: 16px;
  }

  &.-horizontal {
    display: inline-block;
    vertical-align: baseline;
  }
}
