.aside {
  @include grid-column-offset(1);
  text-align: center;

  @include breakpoint(tablet) {
    @include grid-column-offset(0);
    padding-top: 30px;
  }

  & .box.-news {
    margin-top: 45px;
  }

  &.-shadow {
    box-shadow: 0 2px 8px rgba(map-get($colors, black), 0.12);
  }

  &__row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 20px;
    padding-bottom: 0;
    text-align: left;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      margin-top: 20px;
      background-color: map-get($colors, gray100);
    }

    &:last-child {
      padding-bottom: 20px;

      &::after {
        display: none;
      }
    }

    &.-center {
      justify-content: center;
    }

    & img {
      flex-grow: 0;
      align-self: center;
    }

    & .paragraph {
      margin-bottom: 0.6em;
      word-break: break-word;
      hyphens: auto;
    }

    & .title.-h5 {
      margin-bottom: 0.6em;
    }

    & iframe {
      height: 300px;
      margin-top: 1.5em;
    }
  }

  &__column {
    width: 100%;
    flex-basis: 100%;
    padding: 0 5px;

    &.-half {
      flex-basis: calc(50% - 10px);
    }

    &:last-child :last-child {
      margin-bottom: 0;
    }
  }
}
