// General
body {
  font-family: $font-family;
  font-size: $font-size;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  & a {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
  }
}

b,
strong {
  font-weight: bold;
  line-height: inherit;
}

i,
em {
  font-style: italic;
  line-height: inherit;
}

// Titles
.title {
  font-family: $font-family;
  font-weight: 700;
  color: map-get($colors, gray800);

  & a {
    @extend .link;
  }

  &.-main {
    color: map-get($colors, main);
  }

  &.-line {
    position: relative;
    display: inline-block;
    margin-left: 64px;
    margin-right: 64px;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      right: -24px;
      bottom: 0;
      left: -24px;
      background-color: map-get($colors, white);

      @include breakpoint(mobile) {
        right: -14px;
        left: -14px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -2;
      top: 50%;
      right: -64px;
      left: -64px;
      height: 1px;
      background-color: map-get($colors, gray300);

      @include breakpoint(mobile) {
        right: -34px;
        left: -34px;
      }
    }
  }

  &.-white {
    color: map-get($colors, white);
    text-shadow: 0 1px 2px rgba(map-get($colors, gray800), 0.25);
  }

  &.-alt {
    font-family: $font-family-alt;
    text-transform: uppercase;
  }

  &.-h1 {
    margin-bottom: 0.45em;
    font-size: 40px;
    line-height: 1.2em;

    @include breakpoint(mobile) {
      font-size: 28px;
    }

    &.-alt {
      margin-bottom: 0.25em;
      font-size: 60px;
      line-height: 1em;
      letter-spacing: 0.06em;

      @include breakpoint(mobile) {
        font-size: 38px;
      }
    }

    &.-small {
      font-size: 40px;

      @include breakpoint(mobile) {
        font-size: 28px;
      }
    }
  }

  &.-h2 {
    margin-bottom: 0.4em;
    font-size: 24px;
    line-height: 1.35em;

    @include breakpoint(mobile) {
      font-size: 18px;
    }
  }

  &.-h3 {
    margin-bottom: 0.4em;
    font-size: 20px;
    line-height: 1.35em;

    @include breakpoint(mobile) {
      font-size: 18px;
    }

    &.-alt {
      margin-bottom: 0.5em;
      line-height: 1.2em;

      @include breakpoint(mobile) {
        font-size: 16px;
      }
    }

    &.-semiLarge {
      font-size: 22px;
      line-height: 1.2em;

      @include breakpoint(mobile) {
        font-size: 18px;
      }
    }

    &.-large {
      font-size: 24px;
      line-height: 1.2em;

      @include breakpoint(mobile) {
        font-size: 18px;
      }
    }

    &.-marginBottom {
      margin-bottom: 20px;
    }
  }

  &.-h4 {
    margin-bottom: 0.4em;
    font-size: 16px;
    line-height: 1.35em;

    @include breakpoint(mobile) {
      font-size: 14px;
    }

    &.-alt {
      line-height: 1.2em;
      letter-spacing: 0.06em;

      @include breakpoint(mobile) {
        font-size: 13px;
      }
    }
  }

  &.-h5 {
    margin-bottom: 0.4em;
    font-size: 14px;
    line-height: 1.35em;

    &.-alt {
      line-height: 1.2em;
      letter-spacing: 0.06em;

      @include breakpoint(mobile) {
        font-size: 12px;
      }
    }
  }

  &.-h6 {
    margin-bottom: 0.4em;
    font-size: 12px;
    line-height: 1.35em;

    &.-alt {
      line-height: 1.2em;
      letter-spacing: 0.06em;

      @include breakpoint(mobile) {
        font-size: 11px;
      }
    }

    &.-line {
      display: block;
      flex-basis: 100%;
      margin: 0 0 8px;
      padding: 0 30px;
      font-size: 10px;
      color: map-get($colors, main);

      @include breakpoint(mobile) {
        padding: 0 15px;
      }

      & span {
        position: relative;
        display: inline-block;
        padding: 0 8px;
        background-color: map-get($colors, white);
        box-decoration-break: clone;

        &::after {
          content: '';
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: map-get($colors, white);
        }
      }

      &::before {
        display: none;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: auto;
        width: 100%;
        height: 2px;
        transform: translateY(-50%);
        background-color: map-get($colors, main);
      }
    }
  }
}

// Paragraphs
.paragraph {
  margin-bottom: 1.4rem;
  font-family: $font-family;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.625em;
  color: map-get($colors, gray800);

  @include breakpoint(mobile) {
    font-size: 14px;
  }

  & a {
    @extend .link;
  }

  &.-small {
    margin-bottom: 1em;
    font-size: 14px;
    line-height: 1.3em;
  }

  &.-gray {
    color: map-get($colors, gray600);
  }

  &.-lightGray {
    color: map-get($colors, gray400);
  }

  &.-white {
    color: map-get($colors, white);
    text-shadow: 0 1px 2px rgba(map-get($colors, gray800), 0.25);
  }

  &.-light {
    font-weight: 300;
  }

  &.-hero {
    max-width: 650px;
    margin-right: auto;
    margin-left: auto;
    font-size: 22px;
    line-height: 1.3em;

    @include breakpoint(mobile) {
      font-size: 18px;
    }
  }

  &.-preamble {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.6em;

    @include breakpoint(mobile) {
      font-size: 17px;
    }
  }

  &.-puff {
    font-weight: 300;
    font-size: 18px;
    line-height: 1.4em;
  }

  &.-caption {
    font-weight: 300;
    font-size: 12px;
    font-style: italic;

    @include breakpoint(mobile) {
      font-size: 11px;
    }
  }
}

// Blockquote
blockquote,
.blockquote {
  padding: 20px;
  border-left: solid 6px map-get($colors, main);
  margin-top: 2em;
  margin-bottom: 2em;

  & p {
    margin-bottom: 0;
    font-weight: 300;
    color: map-get($colors, gray600);
  }
}

// Extend defaults
h1 {
  @extend .title;
  @extend .-h1;
}

h2 {
  @extend .title;
  @extend .-h2;
}

h3 {
  @extend .title;
  @extend .-h3;
}

h4 {
  @extend .title;
  @extend .-h4;
}

h5 {
  @extend .title;
  @extend .-h5;
}

p {
  @extend .paragraph;
}

.content ul,
.content ol {
  margin-bottom: 1.4rem;
  list-style: none;

  & li {
    @extend .paragraph;
    position: relative;
    margin-bottom: 0.4rem;
    padding-left: 19px;
    line-height: 1.5em;

    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 0;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background-color: map-get($colors, main);
    }
  }
}

.content ol {
  counter-reset: unorderd-list;

  & li {
    counter-increment: unorderd-list;

    &::before {
      top: 0;
      content: counter(unorderd-list);
      width: auto;
      height: auto;
      font-size: 1em;
      font-family: $font-family;
      font-weight: 700;
      color: map-get($colors, main);
      background-color: transparent;
    }
  }
}
