.footer {
  border-top: solid 1px map-get($colors, gray200);

  &__search {
    padding: 40px 0;
    background-color: map-get($colors, gray50);
    text-align: center;
  }

  &__form {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-end;
    margin-top: 20px;
    padding: 0 20px;

    @include breakpoint(tablet) {
      justify-content: flex-end;
      align-items: baseline;
    }

    & > span {
      display: inline-block;
      vertical-align: baseline;
      margin-bottom: 20px;
      font-size: 30px;
      line-height: 1.5em;
      color: map-get($colors, gray800);

      @include breakpoint(mobile) {
        font-size: 18px;
      }
    }

    & .input,
    & .select,
    & .button {
      vertical-align: bottom;
      margin: 0 10px 20px;
    }

    & .input.-text {
      width: auto;

      @include breakpoint(tablet) {
        width: 100%;
        margin: 0 0 10px;
      }
    }

    & .select.-large {
      @include breakpoint(tablet) {
        width: auto;
        flex-basis: 85%;
        flex-grow: 1;
        margin-right: 0;
      }
    }

    & .button {
      @include breakpoint(tablet) {
        margin-right: 0;
      }
    }

    & .select__label {
      background-color: map-get($colors, gray50);
      text-transform: lowercase;
    }
  }

  &__info {
    padding: 40px 0 60px;
    background-color: map-get($colors, gray900);
    text-align: center;

    @include breakpoint(mobile) {
      padding-bottom: 50px;
    }

    & p {
      margin-bottom: 0;
    }

    & ul {
      font-size: 0;
      margin-bottom: 10px;

      @include breakpoint(tablet) {
        margin-bottom: 15px;
      }

      // Fix for contact info
      &:first-of-type {
        @include breakpoint(tablet) {
          & li {
            display: block;
            padding: 0;

            &::before {
              display: none;
            }
          }
        }
      }
    }

    & li {
      position: relative;
      display: inline-block;
      vertical-align: baseline;

      & + li {
        margin-left: 15px;
        padding-left: 15px;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          height: 6px;
          width: 1px;
          background-color: map-get($colors, main);
          opacity: 0.8;
          box-shadow: -1px 0 0 rgba(map-get($colors, main), 0.3);
        }
      }
    }
  }

  &__partners {
    padding: 40px 0 10px;

    & .grid {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
    }

    & .grid__column {
      text-align: center;

      @include breakpoint(tablet) {
        flex-basis: 49%;
      }
    }

    & a {
      display: inline-block;
      margin: 0 30px 40px;
    }
  }
}
