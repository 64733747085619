.box {
  position: relative;
  border: solid 2px map-get($colors, gray200);
  padding: 20px;
  text-align: left;

  & + .box,
  & + div.ad {
    margin-top: 30px;
  }

  &__header {
    transform: translateY(-50%);
    margin-bottom: 5px;
    width: 100%;
    text-align: center;

    & > .title {
      display: inline-block;
      width: 170px;
      padding: 0 20px;
      margin-bottom: 0;
      background-color: map-get($colors, white);
    }
  }

  &__links {
    margin-bottom: 18px;
    font-size: 14px;

    & li {
      margin-bottom: 14px;
      padding: 0 5px;
    }
  }

  &.-news {
    margin-top: 7px;
    padding: 0 15px 10px;

    & + & {
      margin-top: 40px;
    }
  }

  &.-newsletter {
    padding-bottom: 25px;

    & label {
      display: inline-block;
      margin-bottom: 10px;
    }

    & .input {
      margin-bottom: 20px;
      width: 100%;
    }

    &.-alt {
      & .paragraph {
        display: inline-block;
        vertical-align: baseline;
        margin-bottom: 20px;
        margin-right: 2px;
        margin-left: 2px;
      }

      & .input {
        display: inline-block;
        vertical-align: baseline;
        margin-right: 2px;
        margin-left: 2px;
        width: auto;
      }
    }
  }

  &.-alt {
    margin-top: 70px;
    margin-bottom: 20px;
    padding: 30px;
    border: solid 1px map-get($colors, main);
    border-radius: 2px;
    text-align: center;

    & .paragraph {
      max-width: 650px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  & > .title {
    margin-bottom: 0.4em;
  }
}
