.post-type-archive-event {
  & .section.-overlap {
    padding-top: 25px;
    min-height: 110px;

    & .label {
      margin: 0 10px;
    }
  }
}
