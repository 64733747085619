.header {
  position: relative;
  z-index: 8;
  background-color: map-get($colors, headerBackground);

  @include breakpoint(tablet) {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;
    width: 100%;
    backface-visibility: hidden;
    border-bottom: solid 1px map-get($colors, headerBorder);
  }

  &__top {
    border-bottom: solid 1px map-get($colors, headerBorder);
    background-color: map-get($colors, headerBackground);

    @include breakpoint(tablet) {
      display: none;
    }
  }

  &__logo {
    text-align: center;
    padding-top: 35px;
    padding-bottom: 15px;
    background-color: map-get($colors, headerBackground);

    & a:focus {
      outline: none;
    }

    @include breakpoint(tablet) {
      display: inline-block;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: left;
    }
  }

  &__bars {
    display: none;

    & .grid__column:last-child {
      padding-right: 0;
    }

    @include breakpoint(tablet) {
      display: inline-block;
    }
  }

  &__hamburger {
    @include reset-input('button');
    margin: 10px 0;
    padding: 10px;
  }

  &__sticky {
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    backface-visibility: hidden;

    @include breakpoint(tablet) {
      visibility: hidden;
      height: 0;
      flex-basis: 100%;
    }

    &.-sticky {
      position: fixed;
    }

    .admin-bar & {
      top: 32px;
    }
  }

  &__nav {
    position: relative;
    border-bottom: solid 1px map-get($colors, headerBorder);
    background-color: map-get($colors, headerBackground);
  }

  &__line {
    position: absolute;
    z-index: 1;
    bottom: -1px;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 840px;
    margin: 0 auto;
    height: 3px;
    visibility: hidden;
    background-color: map-get($colors, main);
    opacity: 0;
    transition: all 300ms map-get($easings, appleEaseAlt);
    will-change: opacity, visibility;

    &.-active {
      visibility: visible;
      opacity: 1;
    }

    @include breakpoint(tablet) {
      display: none;
    }
  }

  &__search {
    position: absolute;
    z-index: -9;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background-color: rgba(map-get($colors, black), 0.8);
    transition: all 200ms map-get($easings, appleEase);
    will-change: opacity, transform;

    &.-open {
      opacity: 1;
      transform: translateY(100%);
    }

    & input {
      @include reset-input('text');
      width: 100%;
      border: 0;
      padding: 30px 0;
      background: transparent;
      font-family: $font-family;
      font-size: 40px;
      line-height: 1em;
      color: map-get($colors, white);
      letter-spacing: 0.06em;

      &[type=search]::-webkit-search-decoration,
      &[type=search]::-webkit-search-cancel-button,
      &[type=search]::-webkit-search-results-button,
      &[type=search]::-webkit-search-results-decoration {
        display: none;
      }

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: map-get($colors, white);
      }
    }

    & button {
      @include reset-input('button');
      color: rgba(map-get($colors, white), 0.35);
      padding: 0 20px;

      & .icon {
        width: 18px;
        height: 18px;
      }
    }

    &.-responsive {
      position: relative;
      z-index: 1;
      display: flex;
      flex-flow: row nowrap;
      opacity: 1;
      background-color: map-get($colors, gray900);

      & input {
        font-size: 20px;
        padding: 12px 20px 14px;

        &::placeholder {
          color: rgba(map-get($colors, white), 0.35);
          line-height: normal;
        }
      }
    }
  }
}
