.logo {
  display: inline-block;
  vertical-align: middle;
  text-indent: -99999px;
  overflow: hidden;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  &.-header {
    width: auto;
    height: $logo-main-height;

    @include breakpoint(tablet) {
      height: 20px;
    }
  }

  &.-footer {
    width: auto;
    height: 36px;
    margin-bottom: 25px;

    @include breakpoint(mobile) {
      height: 26px;
    }
  }
}
