.hero {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 105px;
  padding-bottom: 105px;
  text-align: center;

  @include breakpoint(mobile) {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: linear-gradient(transparent, rgba(map-get($colors, gray900), 0.7));
  }

  & :last-child,
  & .title:last-child {
    margin-bottom: 0;
  }

  & .title.-h1 {
    word-break: break-word;
    // animation: slideInDown 600ms map-get($easings, appleEase) both;
  }

  & .paragraph.-preamble,
  & .paragraph.-hero {
    max-width: 720px;
    margin-right: auto;
    margin-left: auto;
    // animation: slideInDown 800ms map-get($easings, appleEase) both;
  }

  & .button {
    // animation: slideInDown 1000ms map-get($easings, appleEase) both;
  }

  &__background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: map-get($colors, gray800);
    background-size: cover;
    background-position: center center;
    filter: contrast(0.7) brightness(0.7) saturate(1.7);
  }

  &__content {
    margin: 40px auto 0;
    // animation: slideInDown 1.2s map-get($easings, appleEase) both;

    & form {
      margin-top: 20px;
      display: flex;
      flex-flow: row wrap;
      align-items: baseline;
      justify-content: center;
    }

    & .input,
    & .label,
    & .button {
      margin: 15px 5px 0;
      flex-shrink: 0;
      animation: none;
    }

    & .input.-text {
      width: auto;
    }
  }

  &__featured {
    margin-top: 40px;
    // animation: fadeIn 1.2s map-get($easings, appleEase) both;

    @include breakpoint(tablet) {
      margin-top: 30px;
    }

    & img {
      margin-bottom: 20px;
      height: 60px;
    }
  }

  &__tag {
    margin-bottom: 20px;
    text-align: center;
    // animation: slideInDown 0.8s map-get($easings, appleEase) both;

    &::after {
      display: inline-block;
      content: '';
      width: 30px;
      height: 2px;
      margin-top: 10px;
      background-color: map-get($colors, main);
    }

    & .title {
      color: rgba(map-get($colors, white), 0.8);
    }
  }

  &.-large {
    padding-top: 80px;
    padding-bottom: 140px;

    @include breakpoint(mobile) {
      padding-top: 70px;
      padding-bottom: 110px;
    }
  }

  &.-alt {
    padding-top: 70px;
    padding-bottom: 70px;
    border-bottom: solid 1px map-get($colors, gray200);
    background-color: map-get($colors, gray25);

    @include breakpoint(mobile) {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &::before {
      display: none;
    }

    &.-white {
      background-color: map-get($colors, white);
    }

    &.-borderless {
      border-bottom: 0;
    }
  }

  &.-article {
    padding-top: 50px;
    padding-bottom: 60px;

    @include breakpoint(mobile) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    & .title.-h1 {
      font-size: 52px;

      @include breakpoint(mobile) {
        font-size: 32px;
      }
    }
  }
}
