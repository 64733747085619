.article {
  @include breakpoint(tablet) {
    display: flex;
    flex-flow: row wrap;
  }

  & .aside {
    @include breakpoint(tablet) {
      order: 10;
    }
  }

  & .link.-alt.-blue {
    margin-bottom: 10px;
  }

  &__info {
    display: flex;
    flex-flow: row no-wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
    margin-bottom: 16px;
    border-top: dotted 1px map-get($colors, gray300);
    border-bottom: dotted 1px map-get($colors, gray300);

    & > div {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin: 0 8px;
      color: map-get($colors, gray600);
    }

    & figure {
      flex-shrink: 0;
      margin: 0 8px;
    }

    & img {
      width: 50px;
      height: 50px;
      border-radius: 50%;

      @include breakpoint(mobile) {
        width: 30px;
        height: 30px;
      }
    }

    &.-small {
      padding: 0;
    }

    &.-borderless {
      border-top: none;
      border-bottom: none;
    }
  }

  &__figure {
    margin-bottom: 2em;
    width: 100% !important;

    & img {
      width: 100%;
    }

    & p {
      width: 100%;
      padding: 15px 15px 0;
      margin-bottom: 0;
      font-size: 14px;
      font-style: italic;
      color: map-get($colors, gray500);

      @include breakpoint(mobile) {
        font-size: 11px;
      }
    }
  }

  &__about {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    margin-bottom: 30px;

    & .grid {
      padding-top: 50px;
      border: solid 1px map-get($colors, gray100);

      & p:not(.wp-caption-text) {
        @extend .-gray;
      }
    }

    & > .title.-alt {
      position: absolute;
      top: 0;
      left: 0;
      padding: 12px 20px;
      margin-bottom: 0;
      border: solid 1px map-get($colors, gray100);
      line-height: 1em;
      background-color: map-get($colors, gray25);
    }

    & .grid .title {
      color: map-get($colors, gray600);
    }

    & ul {
      @extend .paragraph;
      @extend .-gray;
      list-style: none;
    }
  }

  &__nav {
    display: flex;
    flex-flow: row no-wrap;
    justify-content: space-between;
    padding: 20px;
    border-top: solid 2px map-get($colors, gray100);
    border-bottom: solid 2px map-get($colors, gray100);
    background-color: map-get($colors, gray25);

    & .link.-alt span {
      @include breakpoint(mobile) {
        display: none;
      }
    }
  }

  &__comments {
    padding: 70px 40px 20px;

    @include breakpoint(mobile) {
      padding: 40px 20px 10px;
    }
  }

  &__video {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    margin-bottom: 30px;

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__sender {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
    padding: 30px 20px;
    border-top: dotted 1px map-get($colors, gray300);
    border-bottom: dotted 1px map-get($colors, gray300);

    @include breakpoint(mobile) {
      flex-flow: column wrap;
    }

    & > div {
      flex-basis: 50%;
    }

    & img {
      max-height: 100px;
      max-width: 40%;

      @include breakpoint(mobile) {
        order: -1;
        margin-bottom: 24px;
      }
    }

    & .title {
      @include breakpoint(mobile) {
        text-align: center;
      }
    }

    & .paragraph {
      margin-bottom: 0.5em;
      line-height: 1.4em;

      @include breakpoint(mobile) {
        text-align: center;
      }
    }

    & a {
      display: inline-block;
      margin-top: 0.8em;
    }
  }

  &__related {
    margin-top: 70px;
    border-top: solid 3px map-get($colors, gray200);

    @include breakpoint(tablet) {
      margin-bottom: 40px;
      margin-top: 40px;

      & .grid__column:last-of-type .card {
        margin-bottom: 0;
      }
    }

    & .title.-h4.-alt {
      padding: 30px 0;
      text-align: center;
    }
  }
}
