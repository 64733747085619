@media print {
  .header,
  .footer,
  .ad,
  .share,
  .article__comments,
  .article__nav,
  .aside,
  .article__related,
  .nav.-aside,
  .cookieNotice,
  #wpadminbar {
    display: none !important;
  }

  .article .grid__column,
  .page .grid__column {
    width: 100%;
  }
}
