.separator {
  margin-top: 30px;
  margin-bottom: 30px;
  border: 0;
  flex-grow: 1;
  height: 3px;
  width: 100%;
  background-color: map-get($colors, gray200);

  & + & {
    display: none;
  }

  &.-small {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  &.-noMarginTop {
    margin-top: 0;
  }

  &.-noMarginBottom {
    margin-bottom: 0;
  }

  &.-light {
    background-color: map-get($colors, gray100);
  }

  &.-hidden {
    background-color: transparent;
  }
}
