.nav {
  text-align: center;

  &__list {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    font-size: 0;
    text-align: center;
    white-space: nowrap;
  }

  &__item {
    display: inline-block;
    vertical-align: middle;
    padding: 0 15px;

    &.-sub .nav__link {
      text-transform: none;
      color: map-get($colors, gray600);
    }

    &.-more {
      position: relative;
    }

    &.-search {
      & a.nav__link {
        padding: 20px 0;
      }

      & .icon {
        width: 22px;
        height: 22px;
      }
    }

    &.-topics {
      padding: 0;
    }

    &.-logo {
      position: absolute;
      padding: 0;
      overflow: hidden;
      opacity: 0;
      will-change: opacity, width, transform;
      transition: all 200ms map-get($easings, swiftOut);

      &.-visible {
        opacity: 1;
      }

      & a {
        margin: 0 15px;
      }

      & img {
        height: $logo-nav-height;
      }
    }
  }

  &__link {
    display: inline-block;
    vertical-align: top;
    padding: 25px 0;
    border: 0;
    font-family: $font-family-alt;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    color: map-get($colors, gray800);
    background-color: transparent;
    transition: color 0.2s map-get($easings, appleEase);
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    user-select: none;

    &:hover,
    &:focus,
    &.-active {
      outline: none;
      color: map-get($colors, main);
    }

    .-logo & {
      padding: 10px 0;
    }

    & .icon.-more {
      width: 10px;
      height: 10px;
      margin-left: 4px;
    }

    & .icon.-close {
      display: none;
    }

    &.-active {
      & .icon.-search {
        display: none;
      }

      & .icon.-close {
        display: block;
      }
    }

    &.-favorite + .nav__heart {
      & span {
        color: map-get($colors, white);
        background-color: map-get($colors, main);
      }

      & .-active {
        display: inline-block;
      }

      & .-inactive {
        display: none;
      }
    }
  }

  & .nav__heart {
    cursor: pointer;
    padding: 15px 10px;

    &:hover {
      & span {
        color: map-get($colors, white);
        background-color: map-get($colors, main);
      }
    }

    & span {
      display: inline-block;
      border-radius: 30px;
      height: 30px;
      width: 30px;
      color: map-get($colors, main);
      transition: all 200ms map-get($easings, swiftOut);
    }

    & .icon {
      height: 16px;
      width: 16px;
      margin: 7px;
    }

    & .-active {
      display: none;
    }
  }

  // Sub nav
  &.-sub {
    text-align: right;

    & .nav__list {
      width: auto;
    }

    & .nav__item {
      padding: 0 15px;

      &.-language {
        & .nav__link {
          padding-top: 15px;
          padding-bottom: 15px;
        }

        & img {
          height: 16px;
          border-radius: 2px;
        }
      }
    }

    & .nav__link {
      padding: 17px 0;
      font-size: 12px;
      text-transform: none;
      letter-spacing: 0;
      color: #666666;

      &:hover,
      &:focus {
        color: map-get($colors, gray800);
      }

      &.-active {
        color: map-get($colors, main);
      }
    }
  }

  // Dropdown
  &.-dropdown {
    position: absolute;
    z-index: -1;
    top: 100%;
    left: 0;
    right: 0;
    visibility: hidden;
    overflow: hidden;
    width: 100%;
    max-width: 840px;
    margin: 0 auto;
    background-color: map-get($colors, white);
    box-shadow: 0 10px 12px rgba(map-get($colors, black), 0.3);
    transform: translateY(-100%);
    opacity: 0;
    transition: all 200ms map-get($easings, swiftOut);
    will-change: opacity, visibility, transform;
    -webkit-tap-highlight-color: transparent;

    @include breakpoint(tablet) {
      display: none;
    }

    &.-visible {
      visibility: visible;
      transform: none;
      opacity: 1;
    }

    & .nav__list {
      display: flex;
      flex-flow: row wrap;
      border-bottom: solid 1px map-get($colors, gray200);
      text-align: left;
      white-space: normal;
    }

    & .nav__item {
      display: flex;
      flex-flow: row no-wrap;
      justify-content: space-between;
      align-items: center;
      width: 33.333%;
      padding: 0;
      border-right: solid 1px map-get($colors, gray200);
      border-bottom: solid 1px map-get($colors, gray200);
      user-select: none;

      &:nth-child(3n) {
        border-right: none;
      }

      &:nth-last-child(-n + 3):nth-child(3n + 1),
      &:nth-last-child(-n + 3):nth-child(3n + 1) ~ .nav__item {
        border-bottom: none;
      }
    }

    & .nav__link {
      flex-grow: 1;
      padding: 24px 0 24px 15px;
      font-size: 12px;
      color: map-get($colors, gray600);

      &:hover,
      &:focus {
        color: map-get($colors, gray900);
      }
    }

    & .nav__register {
      padding: 30px 100px 40px;
      border-top: solid 1px map-get($colors, gray200);
      background-color: map-get($colors, gray50);

      & .title.-h5 {
        border-bottom: solid 1px map-get($colors, gray200);
        padding-bottom: 10px;
        margin: 35px 0 20px;
        text-align: left;
      }
    }
  }

  // Aside
  &.-aside {
    border-right: solid 1px map-get($colors, gray300);
    min-height: 30vh;
    padding-bottom: 120px;
    margin-bottom: 100px;

    @include breakpoint(tablet) {
      display: none;
    }

    &.-ads {
      min-height: 0;
      padding-bottom: 0;
      margin-bottom: 3.2rem;
    }

    & .nav__list {
      overflow: visible;

      & .nav__list {
        display: none;
        background-color: map-get($colors, white);
      }
    }

    & .nav__link {
      display: block;
      padding: 22px 30px;
      font-family: $font-family;
      font-size: 14px;
      text-transform: none;
      letter-spacing: 0;
      color: map-get($colors, gray600);

      &.-title {
        padding: 22px 20px;
        font-family: $font-family;
        font-weight: 700;
        font-size: 16px;
      }

      &:hover,
      &:focus {
        color: map-get($colors, gray800);
      }
    }

    & .nav__item {
      display: block;
      padding: 0;
      border-bottom: solid 1px map-get($colors, gray300);
      text-align: left;
      background-color: map-get($colors, gray50);

      &.-open {
        background-color: map-get($colors, white);

        & > .nav__list {
          display: block;
        }
      }

      &.-active {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          right: -6px;
          top: -1px;
          bottom: -1px;
          width: 6px;
          background-color: map-get($colors, main);
        }

        & > .nav__link {
          color: map-get($colors, gray800);
          font-weight: 700;
        }
      }

      &.-indicator {
        & > .nav__link {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            right: -1px;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-right: 8px solid map-get($colors, main);
          }
        }
      }

      & .nav__item {
        border-bottom: none;
        background-color: map-get($colors, white);

        &:last-child {
          margin-bottom: 15px;
        }

        & .nav__link {
          padding: 12px 40px;
        }

        & .nav__item {
          &:last-child {
            margin-bottom: 20px;
          }

          & .nav__link {
            padding: 8px 50px;
          }
        }
      }
    }

    &.-overlap {
      position: relative;
      z-index: 3;
      margin-top: -40px;
      background-color: map-get($colors, white);

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 30px;
        left: 0;
        display: block;
        border-radius: 2px;
      }

      &::before {
        z-index: -2;
        height: 100px;
        box-shadow: 0 0 10px rgba(map-get($colors, gray800), 0.2);
      }

      &::after {
        z-index: -1;
        right: 0;
        height: 110px;
        background-color: map-get($colors, white);
      }
    }
  }

  // Banner
  &.-banner {
    border-top: solid 2px map-get($colors, gray100);
    border-bottom: solid 2px map-get($colors, gray100);
    background-color: map-get($colors, gray50);

    & .nav__list {
      position: relative;
      padding-top: 20px;
      padding-bottom: 20px;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      text-align: left;

      @include breakpoint(mobile) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    & .nav__item {
      position: relative;
      padding-left: 0;
      padding-right: 12px;
      margin-right: 12px;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 6px;
        width: 1px;
        opacity: 0.4;
        background-color: map-get($colors, main);
        box-shadow: -1px 0 0 rgba(map-get($colors, main), 0.4);
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }

    & .nav__link {
      padding: 15px 0;
      color: map-get($colors, gray600);
      letter-spacing: 0;

      @include breakpoint(mobile) {
        font-size: 12px;
      }

      &:hover,
      &:focus {
        color: map-get($colors, gray800);
      }

      &.-active {
        color: map-get($colors, main);
      }
    }

    &.-small {
      & .nav__list {
        padding: 10px 0;
        text-align: center;
      }

      & .nav__item {
        padding-right: 0;
        margin-right: 0;

        &::after {
          display: none;
        }
      }

      & .nav__link {
        padding-right: 6px;
        padding-left: 6px;

        &.-inactive {
          color: map-get($colors, gray300);
          pointer-events: none;
        }
      }
    }
  }

  // Responsive
  &.-responsive {
    display: none;
    height: 0;
    width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: map-get($colors, gray50);
    will-change: height;

    @include breakpoint(tablet) {
      display: block;
    }

    &.-open {
      min-height: calc(100vh - 61px);
    }

    & .nav__container {
      padding-bottom: 80px;
      will-change: opacity transform;

      & > .nav__list > .nav__item:last-child {
        border-bottom: solid 1px map-get($colors, gray200);
      }

      & > .nav__list > ul {
        border-top: solid 1px map-get($colors, gray200);
        border-bottom: solid 1px map-get($colors, gray200);
      }
    }

    & .nav__list {
      display: block;
      white-space: normal;
      text-align: left;

      &.-favorites {
        border-top: solid 1px map-get($colors, gray200);
        border-bottom: solid 1px map-get($colors, gray200);
      }

      &.-topics {
        display: flex;
        flex-flow: row wrap;
        padding: 0 6px 16px;
        border-bottom: solid 3px map-get($colors, main);
        -webkit-tap-highlight-color: transparent;

        & .nav__item {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          height: 44px;
          border: solid 1px map-get($colors, gray100);
          border-radius: 3px;
          width: calc(50% - 3px);
          margin-bottom: 6px;
          padding: 0;

          &:nth-child(odd) {
            margin-right: 6px;
          }
        }

        & .nav__item .nav__link {
          padding: 16px 0 16px 14px;
          flex-grow: 0;
          flex-basis: calc(100% - 40px);
          overflow-wrap: break-word;
          font-family: $font-family-alt;
          font-size: 10px;
          line-height: 1.2em;
          word-break: break-word;
        }

        & .nav__item .nav__heart {
          padding: 8px;
          flex-shrink: 0;

          & span {
            width: 25px;
            height: 25px;
          }

          & .icon {
            width: 13px;
            height: 13px;
            margin: 6px;
          }
        }
      }
    }

    & .nav__link {
      display: block;
      padding: 17px 0;
      font-size: 13px;
    }

    & .nav__itemToggle {
      display: inline-block;
      flex-shrink: 0;
      padding: 12px 15px 0;
      margin-right: -15px;
      font-size: 20px;
      text-align: center;

      & .icon {
        width: 22px;
        height: 22px;

        &.-close {
          display: none;
        }
      }
    }

    & .nav__itemExpander {
      flex-grow: 1;
      flex-basis: 100%;
      height: 0;
      overflow: hidden;
      margin: 0 -15px;
      will-change: height;
    }

    & .nav__item {
      display: block;
      transition: background-color 100ms map-get($easings, appleEase);

      &.-hasChildren {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        border-top: solid 1px map-get($colors, gray200);

        & .nav__link {
          flex-grow: 1;
        }
      }

      &.-active {
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 6px;
          background-color: map-get($colors, main);
          transition: background-color 200ms map-get($easings, appleEase);
        }

        & > .nav__link {
          color: map-get($colors, main);
        }
      }

      &.-open {
        background-color: map-get($colors, white);

        & > .nav__itemExpander {
          height: auto;
        }

        & > .nav__itemToggle {
          & .icon.-open {
            display: none;
          }

          & .icon.-close {
            display: inline-block;
          }
        }
      }

      &.-indicator {
        position: relative;

        & > .nav__link {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            right: -10px;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-right: 8px solid map-get($colors, main);
          }
        }

        &.-hasChildren > .nav__link::after {
          right: -56px;
        }
      }

      &.-label {
        padding: 30px 15px 10px;
        font-size: 10px;
        font-family: $font-family-alt;
        text-transform: uppercase;
        color: map-get($colors, gray400);
      }

      & + .nav__item {
        border-top: solid 1px map-get($colors, gray200);
      }

      & .nav__item {
        padding-left: 25px;
        border-top: solid 1px map-get($colors, gray200);

        & .nav__link {
          text-transform: none;
          font-size: 13px;
          font-family: $font-family;
          letter-spacing: 0;
          color: map-get($colors, gray600);
        }

        &.-active {
          & > .nav__link {
            color: map-get($colors, gray800);
            font-weight: 700;
          }
        }

        & .nav__item {
          border-top: none;

          &:last-child {
            padding-bottom: 8px;
          }

          & .nav__link {
            padding-top: 13px;
            padding-bottom: 13px;
            font-weight: 400;
          }
        }
      }
    }
  }

  // More dropdown
  &.-more {
    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 50%;
    margin-top: 6px;
    transform: translateX(-50%) translateY(-15px) scale(0.98);
    visibility: hidden;
    width: 260px;
    filter: drop-shadow(0 2px 10px rgba(map-get($colors, black), 0.2)) drop-shadow(0 0 1px map-get($colors, gray200));
    padding: 15px 0;
    border-radius: 2px;
    background-color: map-get($colors, white);
    opacity: 0;
    pointer-events: none;
    transition: opacity 75ms map-get($easings, appleEase), transform 100ms map-get($easings, appleEase), visibility 0ms 100ms linear;

    &.-open {
      visibility: visible;
      opacity: 1;
      transition: opacity 150ms map-get($easings, appleEase), transform 125ms map-get($easings, appleEase);
      transform: translateX(-50%);
      pointer-events: auto;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: 9;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      border-bottom: 12px solid map-get($colors, white);
    }

    & .nav__list {
      white-space: normal;
      text-align: left;
    }

    & .nav__item {
      display: block;
      padding: 0 25px;
    }

    & .nav__link {
      padding: 12px 0;
      font-size: 13px;
    }
  }
}
