.select {
  position: relative;
  z-index: 2;
  display: inline-block;
  vertical-align: top;
  font-family: $font-family;
  max-width: 100%;
  text-align: left;
  white-space: nowrap;

  &.-large {
    font-size: 26px;
    width: 280px;

    @include breakpoint(mobile) {
      font-size: 18px;
    }
  }

  &__label {
    position: relative;
    z-index: 2;
    display: block;
    padding: 5px 45px 5px 0;
    font-family: inherit;
    font-size: 1em;
    line-height: 1em;
    border-bottom: solid 1px map-get($colors, gray500);
    cursor: pointer;
    color: map-get($colors, gray600);
    user-select: none;
    transition: all 200ms map-get($easings, appleEase);
    background-color: map-get($colors, white);

    &:focus,
    &:hover,
    .-open & {
      outline: none;
      border-color: map-get($colors, gray800);
    }

    & span {
      padding: 5px 0;
      display: block;
      overflow: hidden;
    }

    .-active & {
      color: map-get($colors, gray800);
    }
  }

  &__icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    transition: all 200ms map-get($easings, appleEase);

    @include breakpoint(tablet) {
      width: 20px;
      height: 20px;
    }

    .-open & {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  &__list {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    right: 0;
    height: 0;
    max-height: 220px;
    overflow: hidden;
    border-right: solid 1px map-get($colors, gray800);
    border-left: solid 1px map-get($colors, gray800);
    border-bottom: solid 1px map-get($colors, gray800);
    background-color: map-get($colors, white);
    opacity: 0;
    will-change: opacity, transform, height;

    .-open & {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }

    & ~ select {
      display: none;
    }
  }

  &__item {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    color: map-get($colors, gray600);
    transition: color 150ms map-get($easings, appleEase);

    & + & {
      border-top: solid 1px map-get($colors, gray600);
    }

    &:hover,
    &:focus {
      outline: none;
      color: map-get($colors, gray800);
    }
  }
}
