*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
  min-width: 320px;
  cursor: default;
  background-color: map-get($colors, white);

  @include breakpoint(tablet) {
    padding-top: 60px;
  }

  &.admin-bar {
    // padding-top: 32px;

    @include breakpoint(tablet) {
      padding-top: 28px;
    }

    @include breakpoint(782px) {
      padding-top: 14px;
    }
  }

  // JS detection of breakpoints
  &::after {
    display: none;
    content: 'desktop';

    @each $breakpoint, $value in $breakpoints {
      @include breakpoint($breakpoint) {
        content: '#{$breakpoint}';
      }
    }
  }
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

iframe {
  max-width: 100%;
}

::selection {
  color: map-get($colors, white);
  background-color: rgba(darken(map-get($colors, main), 2), 0.8);
}

:focus {
  outline: 1px dotted map-get($colors, gray400);
}
