.single-event {
  & h1 {
    margin-bottom: 0.1em !important;
  }

  & .section {
    padding-bottom: 60px;
  }

  & .grid {
    display: flex;
    flex-flow: row wrap;

    &__column {
      &:nth-child(2) {
        @include breakpoint(tablet) {
          order: -1;
        }
      }
    }

    & .hiddenDesktop {
      margin-left: auto;
      margin-right: auto;
    }
  }

  & .aside {
    margin-left: 0;
    margin-right: 8.333333%;

    @include breakpoint(tablet) {
      padding: 0 !important;
      margin: 1em 0 2em;
    }

    & .-logo {
      display: block;
      overflow: hidden;

      @include breakpoint(tablet) {
        display: none;
      }
    }
  }
}
