.steps {
  &__content {
    text-align: right;

    @include breakpoint(tablet) {
      margin-bottom: 20px;
      text-align: center;
    }

    & h2 {
      @extend .-h1;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 40px;

    @include breakpoint(tablet) {
      max-width: 520px;
      margin-right: auto;
      margin-left: auto;
    }

    @include breakpoint(mobile) {
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0;

      & .steps__icon::after {
        display: none;
      }
    }
  }

  &__icon {
    display: inline-block;
    flex-shrink: 0;
    height: 64px;
    width: 64px;
    padding: 10px;
    margin: 0 25px;
    border: solid 2px lighten(map-get($colors, main), 38%);
    border-radius: 50%;
    color: map-get($colors, main);
    background-color: map-get($colors, white);

    @include breakpoint(mobile) {
      height: 44px;
      width: 44px;
      padding: 5px;
      margin: 0 15px;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0%;
      bottom: -65%;
      left: 57px;
      border-left: dashed 1px lighten(map-get($colors, main), 38%);

      @include breakpoint(mobile) {
        left: 38px;
      }
    }

    & svg {
      height: 40px;
      width: 40px;

      @include breakpoint(mobile) {
        height: 30px;
        width: 30px;
      }
    }
  }
}
