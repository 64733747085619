.hamburger {
  position: relative;
  display: block;
  cursor: pointer;
  width: 20px;
  height: 15px;
  transform-origin: center center;
  will-change: transform;
  transition: transform 300ms map-get($easings, appleEase);

  &.-open {
    transform: rotate(90deg);

    & span {
      &:nth-child(1) {
        top: 6px;
        transform: rotate(45deg) translate3d(0, 0, 0);
        transition: top 200ms map-get($easings, appleEase), transform 300ms 100ms map-get($easings, appleEase);
      }

      &:nth-child(2) {
        opacity: 0;
        transition: opacity 200ms map-get($easings, appleEase);
      }

      &:nth-child(3) {
        top: 6px;
        transform: rotate(-45deg) translate3d(0, 0, 0);
        transition: top 200ms map-get($easings, appleEase), transform 300ms 100ms map-get($easings, appleEase);
      }
    }
  }

  & span {
    position: absolute;
    left: 0;
    display: block;
    height: 1px;
    width: 20px;
    background-color: map-get($colors, gray700);
    transform-origin: center center;
    transform: translate3d(0, 0, 0);
    will-change: top, transform, opacity;
    transition: opacity 300ms 50ms map-get($easings, appleEase);

    &:nth-child(1) {
      top: 0;
      transition: top 200ms map-get($easings, appleEase), transform 200ms map-get($easings, appleEase);
    }

    &:nth-child(2) {
      top: 6px;
    }

    &:nth-child(3) {
      top: 12px;
      transition: top 200ms map-get($easings, appleEase), transform 200ms map-get($easings, appleEase);
    }
  }
}
