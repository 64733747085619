.share {
  padding: 15px 20px 5px;
  font-size: 0;

  @include breakpoint(mobile) {
    padding: 15px 0;
  }

  &__link {
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 10px;
    border-color: map-get($colors, gray300);
    color: map-get($colors, gray600);
    text-align: center;
    text-decoration: none;

    & + .share__link {
      margin-left: 5px;
    }

    &:hover,
    &:focus {
      outline: none;

      &:hover,
      &:focus {
        & .share__icon {
          border-color: map-get($colors, gray600);
          color: map-get($colors, white);
          background-color: map-get($colors, gray600);
        }
      }
    }

    &.-facebook {
      border-color: map-get($colors, facebook);
      color: map-get($colors, facebook);
      fill: map-get($colors, facebook);

      &:hover,
      &:focus {
        & .share__icon {
          border-color: map-get($colors, facebook);
          color: map-get($colors, white);
          background-color: map-get($colors, facebook);
        }
      }
    }

    &.-twitter {
      border-color: map-get($colors, twitter);
      color: map-get($colors, twitter);

      &:hover,
      &:focus {
        & .share__icon {
          border-color: map-get($colors, twitter);
          color: map-get($colors, white);
          background-color: map-get($colors, twitter);
        }
      }
    }

    &.-linkedin {
      border-color: map-get($colors, linkedin);
      color: map-get($colors, linkedin);

      &:hover,
      &:focus {
        & .share__icon {
          border-color: map-get($colors, linkedin);
          color: map-get($colors, white);
          background-color: map-get($colors, linkedin);
        }
      }
    }
  }

  &__icon {
    border: solid 2px map-get($colors, gray300);
    border-color: inherit;
    border-radius: 50px;
    padding: 5px 20px;
    font-size: 14px;
    transition: all 200ms map-get($easings, appleEase);

    & svg {
      max-height: 16px;
    }
  }

  &__text {
    display: block;
    margin-top: 6px;
    font-family: $font-family-alt;
    font-size: 11px;
    text-transform: uppercase;
    color: inherit;
  }
}
