// Breakpoints
$breakpoints: (
  desktop: 1200px,
  tablet: 980px,
  mobile: 520px
);

$tweakpoints: ();

// Grid
$grid-width: 100%;
$grid-max-width: 1200px;
$grid-columns: 12;
$grid-columns-width: (100 / $grid-columns);

$grid-gutter: (
  desktop: 15px,
  tablet: 10px,
  mobile: 5px
);

$grid-column-gutter: (
  desktop: 15px,
  tablet: 10px,
  mobile: 5px
);

// Typography
$font-family: 'Lato', sans-serif;
$font-family-alt: 'Montserrat', sans-serif;
$font-size: 16px;

$font-sizes: (
  large: 60px,
  medium: 42px,
  normal: 16px,
  small: 14px
);

// Easings
$easings: (
  appleEase: cubic-bezier(0.4, 0.01, 0.165, 0.99),
  appleEaseAlt: cubic-bezier(0.28, 0.11, 0.32, 1),
  // In
  easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53),
  easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19),
  easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22),
  easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06),
  easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715),
  easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035),
  easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335),
  easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045),
  // Out
  appleOut: cubic-bezier(0.4, 0.01, 0.165, 0.99),
  swiftOut: cubic-bezier(0.55, 0, 0.1, 1),
  easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94),
  easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1),
  easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1),
  easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1),
  easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1),
  easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1),
  easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1),
  easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275),
  // In out
  easeInOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94),
  easeInOutSine: cubic-bezier(0.39, 0.575, 0.565, 1),
  easeInOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1),
  easeInOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1),
  easeInOutQuint: cubic-bezier(0.23, 1, 0.32, 1),
  easeInOutExpo: cubic-bezier(0.19, 1, 0.22, 1),
  easeInOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1),
  easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55)
);

// Colors
$colors: (
  gray900: #282828,
  gray800: #333333,
  gray700: #4e4e4e,
  gray600: #666666,
  gray500: #838383,
  gray400: #a3a3a3,
  gray300: #dddddd,
  gray200: #e5e5e5,
  gray100: #eeeeee,
  gray50: #f7f7f7,
  gray25: #fcfcfc,
  black: #000000,
  white: #ffffff,
  green: #76c900,
  blue: #008df2,
  red: #e00b48,
  facebook: #3d589a,
  twitter: #5ea7dd,
  googleplus: #dd4b38,
  linkedin: #0077b5,
);
