.link {
  padding-bottom: 2px;
  margin-bottom: -2px;
  border-bottom: dotted 1px map-get($colors, main);
  text-decoration: none;
  color: map-get($colors, gray600);
  transition: color 0.2s map-get($easings, appleEase), background-color 0.2s map-get($easings, appleEase);
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    border-bottom-style: solid;
    text-decoration: none;
    color: map-get($colors, gray800);
    background-color: rgba(map-get($colors, main), 0.1);
  }

  &:active {
    color: map-get($colors, gray);
    background-color: rgba(map-get($colors, main), 0.2);
    transition: all 0.1s map-get($easings, appleEase);
  }

  &.-white {
    color: map-get($colors, white);

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  &.-blue {
    color: map-get($colors, blue);

    &:hover,
    &:focus {
      color: darken(map-get($colors, blue), 10%);
      background-color: transparent;
    }

    &:active {
      color: map-get($colors, blue);
    }
  }

  &.-alt {
    display: inline-block;
    border: none;
    font-family: $font-family-alt;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    color: map-get($colors, complementary);

    &:hover,
    &:focus {
      color: darken(map-get($colors, complementary), 10%);
      background-color: transparent;
    }

    &:active {
      color: map-get($colors, complementary);
    }

    & span {
      display: inline-block;
      vertical-align: middle;

      & + .icon {
        margin-right: 4px;
      }
    }

    & .icon {
      font-size: 17px;
      vertical-align: middle;

      & + span {
        margin-left: 4px;
      }
    }
  }

  &.-simple {
    border: none;

    &:hover,
    &:focus {
      color: map-get($colors, gray800);
      background: none;
    }
  }

  &.-inactive {
    pointer-events: none;
    color: map-get($colors, gray400);

    &:focus {
      color: map-get($colors, gray400);
    }
  }
}
