.form {
  &__validation {
    @extend .paragraph;
    text-align: center;
    margin-top: 10px;
  }

  & .input,
  & .button {
    margin-bottom: 15px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  // Login modal
  .-login & {
    text-align: right;
  }

  // Register
  &.-registerNav {
    display: flex;
    flex-flow: row wrap;

    & .input.-text:-webkit-autofill {
      box-shadow: 0 0 0 1000px map-get($colors, gray50) inset;
    }

    & .input,
    & .button {
      width: auto;
      flex-grow: 1;
      margin: 0 10px 15px;
    }

    & .button.-loading {
      @extend .-loading;
    }

    & .form__feedback {
      flex-grow: 1;
      display: block;
      text-align: center;
    }
  }

  &.-searchPage {
    & .title {
      display: inline-block;
      vertical-align: baseline;
      margin-right: 8px;
      margin-bottom: 0;
    }

    & .input {
      margin-bottom: 0;
      margin-right: 8px;
      vertical-align: baseline;
      min-width: 40%;
    }

    & .button {
      vertical-align: bottom;
    }
  }
}
