.iconList {
  display: flex;
  flex-flow: column wrap;
  list-style: none;

  &__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    margin-bottom: 0;
    line-height: 1.4em;
    color: map-get($colors, gray700);

    & .icon {
      flex-shrink: 0;
      margin-right: 6px;
    }

    & a,
    & .link {
      word-break: break-word;
    }

    & + li {
      margin-top: 10px;
    }
  }

  &.-horizontal {
    margin: 20px 0;
    flex-flow: row wrap;

    & .iconList__item {
      margin: 10px 30px 10px 0;
    }
  }

  &.-green {
    & .iconList__item {
      color: map-get($colors, main);
    }

    & .link {
      color: map-get($colors, main);
    }
  }

  &.-card {
    margin-top: 10px;

    & .iconList__item {
      color: map-get($colors, main);
      font-size: 14px;
    }

    & .link {
      color: map-get($colors, main);
    }

    & .icon {
      height: 1.2em;
      width: 1.2em;
    }
  }
}
