.backdrop {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  background-color: rgba(map-get($colors, black), 0.7);
  opacity: 0;
  transition: opacity 200ms map-get($easings, appleEase), visibility 200ms linear;
  will-change: opacity;

  &.-show {
    visibility: visible;
    opacity: 1;
  }
}

.grid.page {
  display: flex;
  flex-wrap: wrap;

  & > .grid__column.-desktop-3 {
    @include breakpoint(tablet) {
      order: 2;
      margin-bottom: 2.4rem;
    }
  }
}

hr {
  height: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  border-top: dotted 1px map-get($colors, gray300);
}

[class*='wp-image'] {
  width: 100%;
}

.wp-caption {
  margin-bottom: 2em;
  width: 100% !important;

  & img {
    width: 100%;
    margin-bottom: 0;
  }

  & .wp-caption-text {
    width: 100%;
    padding: 15px 15px 0;
    margin-bottom: 0;
    font-size: 14px;
    font-style: italic;
    color: map-get($colors, gray500);

    @include breakpoint(mobile) {
      font-size: 11px;
    }
  }
}

#wpadminbar {
  @include breakpoint(tablet) {
    position: absolute !important;
  }
}

.page-template-default,
.archive {
  @include breakpoint(tablet) {
    & .aside {
      & .box,
      & .separator {
        display: none;
      }
    }
  }
}

.textCenter {
  text-align: center;
}

.heartList {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
  list-style: none;

  &__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    width: calc(50% - 15px);
    margin-bottom: 32px;

    @include breakpoint(tablet) {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  & .icon {
    flex-shrink: 0;
    height: 60px;
    width: 60px;
    fill: map-get($colors, main);
    margin-right: 24px;

    @include breakpoint(mobile) {
      height: 44px;
      width: 44px;
      margin-right: 16px;
    }
  }
}

.formEvents {
  & .button {
    margin-left: 8px;
  }

  @include breakpoint(mobile) {
    & input,
    & label,
    & button {
      margin-bottom: 10px;
    }
  }
}

.usp {
  display: block;
  margin-top: 15px;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  background-color: map-get($colors, white);

  // Hover effect if card is a link
  @at-root a#{&} {
    transition: box-shadow 200ms map-get($easings, appleEase);

    &:hover,
    &:focus {
      box-shadow: 0 5px 15px rgba(map-get($colors, gray900), 0.2);
    }
  }

  & img {
    max-width: 70px;
    margin: 0 auto 20px;
  }
}

.hiddenDesktop {
  display: none;

  @include breakpoint(tablet) {
    display: block;
  }
}
