.grid {
  width: $grid-width;
  max-width: $grid-max-width;
  margin-right: auto;
  margin-left: auto;
  font-size: 0;

  &.-fluid {
    max-width: none;
  }

  &.-pad {
    padding-right: map-get($grid-gutter, desktop);
    padding-left: map-get($grid-gutter, desktop);

    @include breakpoint(tablet) {
      padding-right: map-get($grid-gutter, tablet);
      padding-left: map-get($grid-gutter, tablet);
    }

    @include breakpoint(mobile) {
      padding-right: map-get($grid-gutter, mobile);
      padding-left: map-get($grid-gutter, mobile);
    }
  }

  &.-spacers {
    padding-right: map-get($grid-gutter, desktop) * 2;
    padding-left: map-get($grid-gutter, desktop) * 2;

    @include breakpoint(tablet) {
      padding-right: map-get($grid-gutter, tablet) * 2;
      padding-left: map-get($grid-gutter, tablet) * 2;
    }

    @include breakpoint(mobile) {
      padding-right: map-get($grid-gutter, mobile) * 2;
      padding-left: map-get($grid-gutter, mobile) * 2;
    }
  }

  &.-center {
    text-align: center;

    & .grid__column {
      text-align: left;
    }
  }

  &__reset {
    width: auto;
    margin-right: -(map-get($grid-gutter, desktop) + map-get($grid-column-gutter, desktop));
    margin-left: -(map-get($grid-gutter, desktop) + map-get($grid-column-gutter, desktop));

    @include breakpoint(tablet) {
      margin-right: -(map-get($grid-gutter, tablet) + map-get($grid-column-gutter, tablet));
      margin-left: -(map-get($grid-gutter, tablet) + map-get($grid-column-gutter, tablet));
    }

    @include breakpoint(mobile) {
      margin-right: -(map-get($grid-gutter, mobile) + map-get($grid-column-gutter, mobile));
      margin-left: -(map-get($grid-gutter, mobile) + map-get($grid-column-gutter, mobile));
    }
  }

  &__column {
    display: inline-block;
    vertical-align: top;
    min-height: 1px;
    font-size: $font-size;

    .grid.-pad & {
      padding-right: map-get($grid-column-gutter, desktop);
      padding-left: map-get($grid-column-gutter, desktop);

      @include breakpoint(tablet) {
        padding-right: map-get($grid-column-gutter, tablet);
        padding-left: map-get($grid-column-gutter, tablet);
      }

      @include breakpoint(mobile) {
        padding-right: map-get($grid-column-gutter, mobile);
        padding-left: map-get($grid-column-gutter, mobile);
      }
    }

    &.-no-pad {
      padding-right: 0;
      padding-left: 0;
    }

    &.-desktop {
      @for $i from 1 through $grid-columns {
        &-#{$i} {
          width: #{$i * $grid-columns-width * 1%};
        }
      }

      // Set default width tablet
      @include breakpoint(tablet) {
        @for $i from 1 through $grid-columns {
          &-#{$i} {
            width: 100%;
          }
        }
      }
    }

    &.-tablet {
      @include breakpoint(tablet) {
        @for $i from 1 through $grid-columns {
          &-#{$i} {
            width: #{$i * $grid-columns-width * 1%};
          }
        }
      }

      // Set default width mobile
      @include breakpoint(mobile) {
        @for $i from 1 through $grid-columns {
          &-#{$i} {
            width: 100%;
          }
        }
      }
    }

    &.-mobile {
      @include breakpoint(mobile) {
        @for $i from 1 through $grid-columns {
          &-#{$i} {
            width: #{$i * $grid-columns-width * 1%};
          }
        }
      }
    }
  }
}
